.introAnimation {
  font-size: 72px;
}

@media(max-width: 1024px) {
  .introAnimation {
    font-size: 32px;
  }
}

.introAnimation::after {
  content: '_'!important;
}

.welcomeAnimationWrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}