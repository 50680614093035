body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face { font-family: Modeseven; src: url('./fonts/Modeseven.ttf'); } 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.codeTyping {
  font-family: Modeseven;
  text-transform: uppercase;
  color: #03DA6F;
  text-shadow:
    -2px -2px 0 #000,  
      2px -2px 0 #000,
    -2px  2px 0 #000,
      2px  2px 0 #000;
  text-align: center;
}

.underscore::after {
  content: '_'!important;
}
