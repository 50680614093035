.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.handsWrapper {
  max-width: 800px;
  position: relative;
  padding: 16px;
}

.hands {
  width: 100%;
}

.name {
  font-size: 72px;
}

@media(max-width: 1024px) {
  .name {
    font-size: 32px;
  }
}

@keyframes bounce {
  0%,  
  100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px);
  }
}

.pill {
  display: block;
  width: 100px;
  position: absolute;
  top: 50%;
}

@media(max-width: 600px) {
  .pill {
    width: 75px;
  }
}

.pill img {
  width: 100%;
  animation: bounce 2.5s infinite;
}

.redPill {
  left: 25%;
}

.bluePill {
 right: 25%;
}

.pill:hover img {
  animation: none;
}